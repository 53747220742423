import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useLocation, Link } from "react-router-dom";
import LogoComponent from "@/components/logo/LogoComponent";
import { NavigationItem } from "@/types/globalTypes";
import { MobileNavItem } from "./items/MobileNavItem";

interface MobileNavProps {
    sidebarOpen: boolean;
    setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
    navigation: NavigationItem[];
}

const MobileNav: React.FC<MobileNavProps> = ({
    sidebarOpen,
    setSidebarOpen,
    navigation,
}) => {
    const location = useLocation();
    const pathname = location.pathname;

    const handleLinkClick = () => {
        setSidebarOpen(false);
    };

    return (
        <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-50 lg:hidden"
                onClose={setSidebarOpen}
            >
                <div className="fixed inset-0 flex">
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <Dialog.Panel className="relative flex w-full max-w-xs flex-1">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute right-0 top-0 pr-4 pt-5">
                                    <button
                                        type="button"
                                        className="-m-2.5 p-2.5"
                                        onClick={() => setSidebarOpen(false)}
                                    >
                                        <span className="sr-only">
                                            Close sidebar
                                        </span>
                                        <XMarkIcon
                                            className="h-6 w-6 dark:text-white"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white pb-4 dark:bg-gray-800">
                                <div className="flex h-16 shrink-0 items-center pt-4">
                                    <Link
                                        to="/dashboard"
                                        className="mx-auto"
                                        onClick={handleLinkClick}
                                    >
                                        <LogoComponent className="mx-auto mt-4 h-20 w-auto" />
                                    </Link>
                                </div>
                                <nav className="flex flex-1 flex-col pt-4">
                                    <ul className="flex flex-1 flex-col gap-y-2">
                                        {navigation.map((navsection, index) => (
                                            <MobileNavItem
                                                key={index}
                                                item={navsection}
                                                isActive={
                                                    navsection.href === pathname
                                                }
                                                onClick={handleLinkClick}
                                                disabled={navsection.disabled}
                                            />
                                        ))}
                                    </ul>
                                </nav>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default MobileNav;
