import React from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

interface TasksComponentProps {
    missingDataCountData: {
        salesMissingDataCount: number;
        inventoryMissingDataCount: number;
    };
}

interface TaskBarProps {
    count: number;
    text: string;
    link: string;
}

const TaskBar: React.FC<TaskBarProps> = ({ count, text, link }) => (
    <div className="my-2 flex items-center rounded-lg bg-gray-200 p-3 dark:bg-gray-700">
        <div className="mr-2 rounded-full bg-brand_yellow p-1">
            <ExclamationTriangleIcon className="h-5 w-5 text-yellow-800" />
        </div>
        <div className="text-sm dark:text-white/50">
            <span className="font-bold">{count}</span> {text}{" "}
            <Link className="text-brand" to={link}>
                Fix now.
            </Link>
        </div>
    </div>
);

const TasksComponent: React.FC<TasksComponentProps> = ({
    missingDataCountData,
}) => {
    const salesMissingDataCount = missingDataCountData?.salesMissingDataCount;
    const inventoryMissingDataCount =
        missingDataCountData?.inventoryMissingDataCount;

    return (
        <div className="self-start rounded-lg bg-white px-4 py-5 shadow dark:bg-gray-800 sm:col-span-4 sm:row-start-2 sm:p-6 2xl:col-span-2">
            <div className="text-sm font-semibold text-gray-600 dark:text-gray-300">
                Tasks
            </div>

            {/* Render TaskBar only if inventoryMissingDataCount is greater than 0 */}
            {inventoryMissingDataCount > 0 && (
                <TaskBar
                    count={inventoryMissingDataCount}
                    text="items in your inventory have missing data."
                    link="/dashboard/inventory/missing-data"
                />
            )}

            {/* Render TaskBar only if salesMissingDataCount is greater than 0 */}
            {salesMissingDataCount > 0 && (
                <TaskBar
                    count={salesMissingDataCount}
                    text="of your sold items have missing data."
                    link="/dashboard/sales/missing-data"
                />
            )}
        </div>
    );
};

export default TasksComponent;
