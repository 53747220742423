import React, { useState } from "react";
import { Link } from "react-router-dom";
import IconComponent from "@/components/icon/IconComponent";
import { NavigationItem, NavigationChild } from "@/types/globalTypes";
import { classNames } from "@/helpers/common/classNames";
import { SidebarNavItemChild } from "./SidebarNavItemChild";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

interface NavItemProps {
    item: NavigationItem | NavigationChild;
    isActive: boolean;
    disabled?: boolean;
}

export const SidebarNavItem: React.FC<NavItemProps> = ({
    item,
    isActive,
    disabled = false,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const hasChildren =
        "children" in item && item.children && item.children.length > 0;

    const toggleDropdown = () => hasChildren && !disabled && setIsOpen(!isOpen);

    return (
        <li>
            <Link
                to={disabled ? "#" : item.href}
                onClick={(e) =>
                    disabled ||
                    (hasChildren && e.preventDefault(), toggleDropdown())
                }
                className={classNames(
                    isActive
                        ? "bg-brand text-white"
                        : "text-gray-700 hover:bg-brand hover:text-white dark:text-gray-200 dark:hover:text-white",
                    disabled
                        ? "group flex cursor-not-allowed items-center justify-between gap-x-3 rounded-md p-3 text-sm font-medium leading-6 opacity-50"
                        : "group flex items-center justify-between gap-x-3 rounded-md p-3 text-sm font-medium leading-6",
                )}
                aria-disabled={disabled}
                tabIndex={disabled ? -1 : 0} // Optional: Make the link unfocusable if disabled
            >
                <span className="flex items-center gap-x-3">
                    {"icon" in item && (
                        <IconComponent
                            iconName={item.icon}
                            className="h-6 w-6 shrink-0"
                            aria-hidden={true}
                        />
                    )}
                    {item.name}
                </span>
                {disabled && (
                    <span className="ml-2 inline-flex h-6 w-20 items-center justify-center rounded-full bg-gray-200 p-1 text-[10px] font-medium text-gray-800 dark:bg-gray-600 dark:text-gray-100">
                        Coming Soon
                    </span>
                )}
                {hasChildren && (
                    <span
                        className={`transition-transform duration-200 ${isOpen ? "rotate-180" : "rotate-0"}`}
                    >
                        <ChevronDownIcon className="h-5 w-5" />
                    </span>
                )}
            </Link>
            {isOpen && hasChildren && (
                <ul className="mt-1 space-y-1 pl-5">
                    {item.children.map((child, index) => (
                        <SidebarNavItemChild
                            key={index}
                            item={child}
                            isActive={child.href === location.pathname}
                            disabled={child.disabled} // Pass down the disabled prop if needed
                        />
                    ))}
                </ul>
            )}
        </li>
    );
};
