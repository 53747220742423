import React, { Fragment, useState } from "react";
import { Dialog, Transition, Switch } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import {
    GET_EBAY_ITEM_SOURCE_LOCATIONS_QUERY,
    GET_EBAY_ITEM_BRANDS_QUERY,
    GET_EBAY_ITEM_TAGS_QUERY,
} from "@/graphql/queries/ebay/general/ebayGeneralQueries";
import { UPDATE_EBAY_ITEM_MUTATION } from "@/graphql/mutations/ebayMutations";
import { useQuery, useMutation } from "@apollo/client";
import Tooltip from "@/components/dashboard/general/TooltipComponent";

interface ModalProps {
    isOpen: boolean;
    closeModal: () => void;
    product: any;
    vatSettings: any;
}

const decodeBase64Id = (encodedId: string): string => {
    const decodedString = atob(encodedId);
    return decodedString.split(":")[1]; // Assuming the format is "Type:ID"
};

const EditProductModal: React.FC<ModalProps> = ({
    isOpen,
    closeModal,
    product,
    vatSettings,
}) => {
    const navigate = useNavigate();

    const { data: sourceLocationsData } = useQuery(
        GET_EBAY_ITEM_SOURCE_LOCATIONS_QUERY,
    );
    const { data: brandsData } = useQuery(GET_EBAY_ITEM_BRANDS_QUERY);
    const { data: tagsData } = useQuery(GET_EBAY_ITEM_TAGS_QUERY);

    const [updateEbayItem] = useMutation(UPDATE_EBAY_ITEM_MUTATION);

    // State to manage the toggle switch
    const [excludeFromCalculations, setExcludeFromCalculations] = useState(
        product.excludedFromCalculations || false,
    );

    const [canReclaimVATOnCOG, setCanReclaimVATOnCOG] = useState(
        product.vatExempt === false,
    );

    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;
        const formData = new FormData(form);

        // Convert the id from base64 to string
        const itemId = decodeBase64Id(product.id);

        const variables: any = { itemId: itemId };

        const costOfGoodsPerItem = formData.get("costOfGoodsPerItem");
        if (costOfGoodsPerItem) {
            variables.costOfGoodsPerItem = parseFloat(
                costOfGoodsPerItem as string,
            ).toFixed(2);
        }

        const packingCostsPerItem = formData.get("packingCostsPerItem");
        if (packingCostsPerItem) {
            variables.packingCostsPerItem = parseFloat(
                packingCostsPerItem as string,
            ).toFixed(2);
        }

        const sourcedAt = formData.get("sourcedAt");
        if (sourcedAt) {
            variables.sourcedAt = new Date(sourcedAt as string).toISOString();
        }

        const sourceLocationId = formData.get("sourceLocation");
        if (sourceLocationId) {
            variables.sourceLocationId = sourceLocationId as string;
        }

        const storageLocation = formData.get("storageLocation");
        if (storageLocation) {
            variables.storageLocation = storageLocation as string;
        }

        const itemBrandId = formData.get("brand");
        if (itemBrandId) {
            variables.itemBrandId = itemBrandId as string;
        }

        const tagIds = formData.getAll("tags");
        if (tagIds.length > 0) {
            variables.tagIds = tagIds as string[];
        }

        // Add the excludeFromCalculations to the variables
        variables.excludedFromCalculations = excludeFromCalculations;

        // Add the canReclaimVATOnCOG to the variables if the user is VAT registered and the VAT type is STANDARD else set it to null
        if (
            vatSettings?.isVatRegistered &&
            vatSettings?.vatType === "STANDARD"
        ) {
            variables.vatExempt = !canReclaimVATOnCOG;
        } else {
            variables.vatExempt = null;
        }

        try {
            await updateEbayItem({ variables });
            closeModal();
            navigate(0);
        } catch (error) {
            console.error("Error updating eBay item:", error);
        }
    };

    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 border-2 bg-white bg-opacity-75 transition-opacity dark:bg-gray-500 dark:opacity-75" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto pt-14">
                    <div className="flex items-end justify-center p-4 text-center sm:items-center lg:px-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg border-2 border-gray-900 bg-white p-4 text-left shadow-xl transition-all dark:border-0 dark:bg-gray-800 sm:my-8 sm:w-full sm:max-w-3xl sm:p-6 lg:ml-80 lg:mr-10 lg:pt-4">
                                <div className="mb-3">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 dark:text-white"
                                    >
                                        Edit Product Details
                                    </Dialog.Title>
                                </div>
                                <div className="mb-3">
                                    <form onSubmit={handleFormSubmit}>
                                        <div className="md:flex md:space-x-4">
                                            <div className="mt-3 pb-2 md:w-1/2">
                                                <div className="flex items-center">
                                                    <label
                                                        htmlFor="costOfGoodsPerItem"
                                                        className="block text-sm font-medium dark:text-white"
                                                    >
                                                        Cost of Goods Per Item
                                                    </label>
                                                    <Tooltip text="You should enter the cost of each item per unit when you purchased it." />
                                                </div>
                                                <input
                                                    type="number"
                                                    step="0.01"
                                                    name="costOfGoodsPerItem"
                                                    id="costOfGoodsPerItem"
                                                    className="mt-1 block w-full rounded-md border border-gray-900 px-3 py-2 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                                                    defaultValue={
                                                        product.costOfGoodsPerItem
                                                    }
                                                />
                                            </div>
                                            <div className="mt-3 pb-2 md:w-1/2">
                                                <div className="flex items-center">
                                                    <label
                                                        htmlFor="packingCostsPerItem"
                                                        className="block text-sm font-medium dark:text-white"
                                                        title="Packing Costs Per Item"
                                                    >
                                                        Packing Costs Per Item
                                                    </label>
                                                    <Tooltip text="Enter the cost of how much it costs to pack each item. This could include the cost of the box, tape, bubble wrap, etc." />
                                                </div>
                                                <input
                                                    type="number"
                                                    step="0.01"
                                                    name="packingCostsPerItem"
                                                    id="packingCostsPerItem"
                                                    className="mt-1 block w-full rounded-md border border-gray-900 px-3 py-2 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                                                    defaultValue={
                                                        product.packingCostsPerItem
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="md:flex md:space-x-4">
                                            <div className="mt-3 pb-2 md:w-1/2">
                                                <div className="flex items-center">
                                                    <label
                                                        htmlFor="sourcedAt"
                                                        className="block text-sm font-medium dark:text-white"
                                                    >
                                                        Purchased Date
                                                    </label>
                                                    <Tooltip text="Enter the date in which you purchased the item." />
                                                </div>
                                                <input
                                                    type="date"
                                                    name="sourcedAt"
                                                    id="sourcedAt"
                                                    className="mt-1 block w-full rounded-md border border-gray-900 px-3 py-2 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                                                    defaultValue={
                                                        product.sourcedAt
                                                            ? new Date(
                                                                  product.sourcedAt,
                                                              )
                                                                  .toISOString()
                                                                  .split("T")[0]
                                                            : ""
                                                    }
                                                />
                                            </div>
                                            <div className="mt-3 pb-2 md:w-1/2">
                                                <div className="flex items-center">
                                                    <label
                                                        htmlFor="sourceLocation"
                                                        className="block text-sm font-medium dark:text-white"
                                                    >
                                                        Source Location
                                                    </label>
                                                    <Tooltip text="Select the location where you purchased the item." />
                                                </div>
                                                <select
                                                    name="sourceLocation"
                                                    id="sourceLocation"
                                                    className="mt-1 block w-full rounded-md border border-gray-900 py-2 pl-3 pr-10 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                                                    defaultValue={
                                                        product.sourceLocation
                                                            ?.id || ""
                                                    }
                                                >
                                                    {sourceLocationsData?.sourceLocations.map(
                                                        (
                                                            sourceLocation: any,
                                                        ) => (
                                                            <option
                                                                key={
                                                                    sourceLocation.id
                                                                }
                                                                value={
                                                                    sourceLocation.id
                                                                }
                                                            >
                                                                {
                                                                    sourceLocation.name
                                                                }
                                                            </option>
                                                        ),
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="md:flex md:space-x-4">
                                            <div className="mt-3 pb-2 md:w-1/2">
                                                <div className="flex items-center">
                                                    <label
                                                        htmlFor="storageLocation"
                                                        className="block text-sm font-medium dark:text-white"
                                                    >
                                                        Storage Location
                                                    </label>
                                                    <Tooltip text="Enter an identifier for the location where you store the item. e.g. Shelf 1, Box 2, etc." />
                                                </div>
                                                <input
                                                    type="text"
                                                    name="storageLocation"
                                                    id="storageLocation"
                                                    className="mt-1 block w-full rounded-md border border-gray-900 px-3 py-2 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                                                    defaultValue={
                                                        product.storageLocation ||
                                                        ""
                                                    }
                                                />
                                            </div>
                                            <div className="mt-3 pb-2 md:w-1/2">
                                                <div className="flex items-center">
                                                    <label
                                                        htmlFor="brand"
                                                        className="block text-sm font-medium dark:text-white"
                                                    >
                                                        Brand
                                                    </label>
                                                    <Tooltip text="Select the brand of the item." />
                                                </div>
                                                <select
                                                    name="brand"
                                                    id="brand"
                                                    className="mt-1 block w-full rounded-md border border-gray-900 py-2 pl-3 pr-10 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                                                    defaultValue={
                                                        product.itemBrand?.id ||
                                                        ""
                                                    }
                                                >
                                                    {brandsData?.brands.map(
                                                        (brand: any) => (
                                                            <option
                                                                key={brand.id}
                                                                value={brand.id}
                                                            >
                                                                {brand.name}
                                                            </option>
                                                        ),
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="md:flex md:space-x-4">
                                            <div className="mt-3 md:w-1/2">
                                                <div className="flex items-center">
                                                    <label
                                                        htmlFor="tags"
                                                        className="block text-sm font-medium dark:text-white"
                                                    >
                                                        Tags
                                                    </label>
                                                    <Tooltip text="Select one or multiple tags for the item. To select multiple tags, hold down the Ctrl key (Cmd on Mac) and click on the tags you want to select. To deselect, hold down the Ctrl key (Cmd on Mac) and click on the selected tag again." />
                                                </div>
                                                <select
                                                    name="tags"
                                                    id="tags"
                                                    className="mt-1 block w-full rounded-md border border-gray-900 py-2 pl-3 pr-10 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                                                    defaultValue={
                                                        product.tags.map(
                                                            (tag: any) =>
                                                                tag.id,
                                                        ) || []
                                                    }
                                                    multiple
                                                >
                                                    {tagsData?.ebayItemTags.map(
                                                        (tag: any) => (
                                                            <option
                                                                key={tag.id}
                                                                value={tag.id}
                                                            >
                                                                {tag.name}
                                                            </option>
                                                        ),
                                                    )}
                                                </select>
                                            </div>
                                            <div className="mt-3 md:w-1/2">
                                                <div className="flex items-center">
                                                    <label
                                                        htmlFor="excludeFromCalculations"
                                                        className="block text-sm font-medium dark:text-white"
                                                    >
                                                        Exclude from
                                                        calculations
                                                    </label>
                                                    <Tooltip text="Check this box if you want to exclude this item from the calculations. This means that the item will not be included in the profit calculations." />
                                                </div>
                                                <Switch
                                                    checked={
                                                        excludeFromCalculations
                                                    }
                                                    onChange={
                                                        setExcludeFromCalculations
                                                    }
                                                    className={`${
                                                        excludeFromCalculations
                                                            ? "bg-brand"
                                                            : "bg-gray-200"
                                                    } relative mt-1 inline-flex h-6 w-11 items-center rounded-full`}
                                                >
                                                    <span className="sr-only">
                                                        Exclude from
                                                        calculations
                                                    </span>
                                                    <span
                                                        className={`${
                                                            excludeFromCalculations
                                                                ? "translate-x-6"
                                                                : "translate-x-1"
                                                        } inline-block h-4 w-4 transform rounded-full bg-white`}
                                                    />
                                                </Switch>
                                                {vatSettings?.isVatRegistered &&
                                                    vatSettings?.vatType ===
                                                        "STANDARD" && (
                                                        <>
                                                            <div className="flex items-center">
                                                                <label
                                                                    htmlFor="canReclaimVATOnCOG"
                                                                    className="block text-sm font-medium dark:text-white"
                                                                >
                                                                    Can Reclaim
                                                                    VAT on COG
                                                                </label>
                                                                <Tooltip text="Check this box if this item is eligible for VAT reclaim on the cost of goods." />
                                                            </div>
                                                            <Switch
                                                                checked={
                                                                    canReclaimVATOnCOG
                                                                } // Use the state directly
                                                                onChange={
                                                                    setCanReclaimVATOnCOG
                                                                } // State update remains unchanged
                                                                className={`${
                                                                    canReclaimVATOnCOG
                                                                        ? "bg-brand"
                                                                        : "bg-gray-200"
                                                                } relative mt-1 inline-flex h-6 w-11 items-center rounded-full`}
                                                            >
                                                                <span className="sr-only">
                                                                    Can Reclaim
                                                                    VAT on COG
                                                                </span>
                                                                <span
                                                                    className={`${
                                                                        canReclaimVATOnCOG
                                                                            ? "translate-x-6"
                                                                            : "translate-x-1"
                                                                    } inline-block h-4 w-4 transform rounded-full bg-white`}
                                                                />
                                                            </Switch>
                                                        </>
                                                    )}
                                            </div>
                                        </div>

                                        <div className="md:mt-4 md:flex md:space-x-4">
                                            <button
                                                type="submit"
                                                className="mt-4 flex w-full justify-center rounded-md bg-brand px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand md:w-1/2"
                                            >
                                                Submit
                                            </button>
                                            <button
                                                type="button"
                                                className="x-3 mt-4 flex w-full justify-center rounded-md border-2 bg-gray-200 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700 dark:border-0 dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500 md:w-1/2"
                                                onClick={closeModal}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default EditProductModal;
