export const Alert: React.FC<{ message: string; onClose: () => void }> = ({
    message,
    onClose,
}) => (
    <div className="mb-4 rounded-md border border-red-400 bg-red-100 px-4 py-3 text-red-700 shadow-md">
        <div className="flex items-center justify-between">
            <span>{message}</span>
            <button
                onClick={onClose}
                className="ml-4 font-bold text-red-700 hover:text-red-800"
            >
                &times;
            </button>
        </div>
    </div>
);
