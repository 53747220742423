import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "@/contexts/AuthContext";
import CircularLoader from "@/components/loading/LoadingComponent";

interface GuardProps {
    children: React.ReactNode;
}

const SettingsGuard = ({ children }: GuardProps) => {
    console.log("Executing SettingsGuard");

    const { ebayAccount, loading } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        console.log("Executing SettingsGuard useEffect");

        if (!loading && ebayAccount && !ebayAccount.initialSettingsCompleted) {
            navigate("/initial-settings");
        }
    }, [ebayAccount, loading, navigate]);

    if (loading) {
        return <CircularLoader />;
    }

    return ebayAccount && ebayAccount.initialSettingsCompleted ? (
        <>{children}</>
    ) : null;
};

export default SettingsGuard;
