import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GET_DASHBOARD_STATS_QUERY } from "@/graphql/queries/ebay/general/ebayGeneralQueries";
import { subDays, startOfToday, endOfToday } from "date-fns";

const useDashboardStats = () => {
    const [filters, setFilters] = useState({
        ebaySaleSoldAtGte: subDays(startOfToday(), 30).toISOString(),
        ebaySaleSoldAtLte: endOfToday().toISOString(),
        ebayItemListedAtGte: subDays(startOfToday(), 30).toISOString(),
        ebayItemListedAtLte: endOfToday().toISOString(),
        tags: [],
        sourceLocationId: null,
        itemCategoryId: null,
        itemBrandId: null,
    });

    const { data, loading, error, refetch } = useQuery(
        GET_DASHBOARD_STATS_QUERY,
        {
            variables: { ...filters },
        },
    );

    useEffect(() => {
        if (data) {
        }
    }, [data]);

    useEffect(() => {
        refetch(filters);
    }, [filters, refetch]);

    const handleFilterChange = (name: string, value: any) => {
        setFilters((prev) => ({ ...prev, [name]: value }));
    };

    const applyFilters = () => {
        refetch(filters);
    };

    const resetFilters = () => {
        const defaultFilters = {
            ebaySaleSoldAtGte: subDays(startOfToday(), 30).toISOString(),
            ebaySaleSoldAtLte: startOfToday().toISOString(),
            ebayItemListedAtGte: subDays(startOfToday(), 30).toISOString(),
            ebayItemListedAtLte: startOfToday().toISOString(),
            tags: [],
            sourceLocationId: null,
            itemCategoryId: null,
            itemBrandId: null,
        };
        setFilters(defaultFilters);
        refetch(defaultFilters);
    };

    return {
        data: data?.dashboardStats,
        loading,
        error,
        filters,
        setFilters: handleFilterChange,
        applyFilters,
        resetFilters,
    };
};

export default useDashboardStats;
