import { AuthContextType } from "@/types/globalTypes";
import { createContext } from "react";

export const AuthContext = createContext<AuthContextType>({
    user: null,
    loading: true,
    isAuthenticated: false,
    subscription: null,
    ebayAccount: null,
    fetchUserData: async () => {},
    loginUser: async () => {},
    logoutUser: async () => {},
    signupUser: async () => ({ success: false, message: "" }),
});
