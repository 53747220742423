import React, { useEffect, useState } from "react";
import { useTheme } from "@/hooks/general/useTheme";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface GraphComponentProps {
    aggregatedData: Record<string, number>;
}

interface ThemeColors {
    axisTextColor: string;
    barColor: string;
}

type ThemeConfig = Record<"light" | "dark", ThemeColors>;

const chartColors: ThemeConfig = {
    light: {
        axisTextColor: "#111827",
        barColor: "#0064d2",
    },
    dark: {
        axisTextColor: "#ffffff",
        barColor: "#0064d2",
    },
};

function getThemeConfigValue(themeConfig: ThemeConfig, key: string) {
    if (key in themeConfig) {
        return themeConfig[key as keyof ThemeConfig];
    }
    throw new Error(`Key ${key} does not exist in ThemeConfig`);
}

const GraphComponent: React.FC<GraphComponentProps> = ({ aggregatedData }) => {
    const { theme } = useTheme();
    const [chartOptions, setChartOptions] = useState<ApexOptions>({});

    useEffect(() => {
        const themeColor = getThemeConfigValue(chartColors, theme);

        const totalPoints = Object.keys(aggregatedData).length;
        const tickAmount = Math.min(totalPoints, 10); // Show a maximum of 10 ticks

        // Determine if the data spans more than one day
        const firstKey = Object.keys(aggregatedData)[0];
        const lastKey = Object.keys(aggregatedData)[totalPoints - 1];
        const isMultiDay =
            new Date(lastKey).getDate() !== new Date(firstKey).getDate();

        setChartOptions({
            chart: {
                id: "profit-bar-graph",
                toolbar: {
                    show: false,
                },
            },
            xaxis: {
                categories: Object.keys(aggregatedData),
                labels: {
                    style: {
                        colors: themeColor.axisTextColor,
                    },
                },
                title: {
                    text: isMultiDay ? "Date" : "Time",
                    style: {
                        color: themeColor.axisTextColor,
                        fontSize: "14px",
                    },
                },
                tickAmount: tickAmount,
            },
            yaxis: {
                labels: {
                    style: {
                        colors: themeColor.axisTextColor,
                    },
                    formatter: (value: number) => `£${value.toFixed(2)}`,
                },
                title: {
                    text: "Profit",
                    style: {
                        color: themeColor.axisTextColor,
                        fontSize: "14px",
                    },
                },
            },
            dataLabels: {
                enabled: false,
            },
            colors: [themeColor.barColor],
            tooltip: {
                enabled: true,
                y: {
                    formatter: (value: number) => `£${value.toFixed(2)}`,
                },
            },
            grid: {
                borderColor: themeColor.axisTextColor,
            },
        });
    }, [theme, aggregatedData]);

    const series = [
        {
            name: "Profit",
            data: Object.values(aggregatedData),
        },
    ];

    return (
        <div className="overflow-hidden rounded-lg bg-white px-4 pt-5 shadow dark:bg-gray-800 sm:col-span-2 sm:col-start-1 sm:row-span-1 sm:p-6">
            <dt className="truncate text-sm font-semibold text-gray-600 dark:text-gray-300">
                Profit
            </dt>
            <dd>
                <div className="mixed-chart">
                    <Chart options={chartOptions} series={series} type="bar" />
                </div>
            </dd>
        </div>
    );
};

export default GraphComponent;
