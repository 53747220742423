import React from "react";
import { useLocation, Link } from "react-router-dom";
import LogoComponent from "@/components/logo/LogoComponent";
import { NavigationItem } from "@/types/globalTypes";
import { SidebarNavItem } from "./items/SidebarNavItem";

interface SidebarNavProps {
    navigation: NavigationItem[];
}

const SidebarNav: React.FC<SidebarNavProps> = ({ navigation }) => {
    const location = useLocation();

    return (
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white pb-4 pt-4 dark:bg-gray-800">
                <div className="mt-2 flex h-14 shrink-0 items-center">
                    <Link to="/dashboard" className="mx-auto">
                        <LogoComponent className="mx-auto mt-3 h-24" />
                    </Link>
                </div>
                <nav className="flex flex-1 flex-col px-4 pt-6">
                    <ul
                        role="list"
                        className="flex flex-1 flex-col gap-y-2 rounded-md"
                    >
                        {navigation.map((item, index) => (
                            <SidebarNavItem
                                key={index}
                                item={item}
                                isActive={item.href === location.pathname}
                                disabled={item.disabled}
                            />
                        ))}
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default SidebarNav;
