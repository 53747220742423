import { useState, useEffect, useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { UserData, Subscription, EbayAccount } from "@/types/globalTypes";
import { router } from "@/router/browserRouter";
import {
    LOGIN_USER_MUTATION,
    LOGOUT_USER_MUTATION,
    SIGNUP_USER_MUTATION,
} from "@/graphql/mutations/authMutations";
import { AuthContext } from "@/contexts/AuthContext";

const MY_QUERY = gql`
    query MyQuery {
        user {
            id
            firstName
            lastName
            email
            emailVerified
            isActive
            ebayAccount {
                id
                ebayUserId
                storeType
                isActive
                initialised
                invalidToken
                initialSettingsCompleted
            }
            isSuperuser
        }
        isAuthenticated
        subscription {
            id
            stripeSubscriptionId
            status
            trialEnd
            currentPeriodStart
            currentPeriodEnd
        }
    }
`;

interface AuthProviderProps {
    children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
    const [user, setUser] = useState<UserData | null>(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [subscription, setSubscription] = useState<Subscription | null>(null);
    const [ebayAccount, setEbayAccount] = useState<EbayAccount | null>(null);
    const [loading, setLoading] = useState(true);
    const client = useApolloClient();
    const { navigate } = router;

    const fetchUserData = useCallback(async () => {
        setLoading(true);
        try {
            const { data } = await client.query({
                query: MY_QUERY,
                fetchPolicy: "network-only",
            });
            if (data.isAuthenticated) {
                setUser(data.user);
                setIsAuthenticated(data.isAuthenticated);
                setSubscription(data.subscription);
                setEbayAccount(data.user.ebayAccount);
            } else {
                setUser(null);
                setIsAuthenticated(false);
                setSubscription(null);
                setEbayAccount(null);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
            setUser(null);
            setIsAuthenticated(false);
            setSubscription(null);
            setEbayAccount(null);
        } finally {
            setLoading(false);
        }
    }, [client]);

    const loginUser = async (email: string, password: string) => {
        const { data } = await client.mutate({
            mutation: LOGIN_USER_MUTATION,
            variables: { email, password },
        });

        if (data.loginUser.success) {
            console.log("Login successful");
            await fetchUserData(); // Refresh user data after login
            navigate("/dashboard"); // Redirect after login success
        } else {
            throw new Error(data.loginUser.message);
        }
    };

    const logoutUser = async () => {
        try {
            await client.mutate({
                mutation: LOGOUT_USER_MUTATION,
            });
            setUser(null);
            setIsAuthenticated(false);
            setSubscription(null);
            setEbayAccount(null);
            navigate("/login");
        } catch (error) {
            console.error("Logout error:", error);
            throw error;
        }
    };

    const signupUser = async (
        email: string,
        password: string,
        firstName: string,
        lastName: string,
    ) => {
        try {
            const { data } = await client.mutate({
                mutation: SIGNUP_USER_MUTATION,
                variables: { email, password, firstName, lastName },
            });

            const { success, message } = data.signupUser;

            if (success) {
                await fetchUserData(); // Refresh user data after successful signup
            }

            return { success, message };
        } catch (error: any) {
            console.error("Sign-up error:", error);

            // Check if the error is a GraphQL error and return the associated message
            const gqlErrors = error.graphQLErrors;
            if (gqlErrors && gqlErrors.length > 0) {
                return {
                    success: false,
                    message:
                        gqlErrors[0].message ||
                        "An error occurred during sign-up. Please try again.",
                };
            }

            // Default error message if no GraphQL error is available
            return {
                success: false,
                message: "An unexpected error occurred. Please try again.",
            };
        }
    };

    useEffect(() => {
        fetchUserData();
    }, [fetchUserData]);

    return (
        <AuthContext.Provider
            value={{
                user,
                loading,
                isAuthenticated,
                subscription,
                ebayAccount,
                fetchUserData,
                loginUser,
                logoutUser,
                signupUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
