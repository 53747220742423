import { decodeBase64Id } from "@/helpers/common/decodeBase64Id";

interface MostProfitableProductComponentProps {
    mostProfitableProductData: MostProfitableProductData | null;
}

interface MostProfitableProductData {
    id: string;
    imageUrl: string;
    itemName: string;
    profitAndRoi: {
        totalProfitLoss: number;
        totalReturnOnInvestment: number;
        totalRevenue: number;
        totalCosts: number;
    };
}

interface StatItem {
    name: string;
    stat: string;
    prefix: string;
    suffix: string;
}

const MostProfitableProductComponent: React.FC<
    MostProfitableProductComponentProps
> = ({ mostProfitableProductData }) => {
    // If data is not available, return a fallback message
    if (!mostProfitableProductData) {
        return (
            <div className="rounded-lg bg-white px-4 py-5 shadow dark:bg-gray-800 sm:col-span-2 sm:col-start-3 sm:row-start-1 sm:p-6">
                <div className="text-sm font-semibold text-gray-600 dark:text-gray-300">
                    No Most Profitable Product Available
                </div>
            </div>
        );
    }

    const { id, imageUrl, itemName, profitAndRoi } = mostProfitableProductData;
    const decodedId = decodeBase64Id(id);

    const getStatClass = (name: string, value: number) => {
        if (isNaN(value)) return "text-gray-900 dark:text-white";
        if (name === "Total Costs") return "text-red-500";
        return value >= 0 ? "text-green-500" : "text-red-500";
    };

    const stats: StatItem[] = [
        {
            name: "Total Revenue",
            stat: Number(profitAndRoi.totalRevenue).toFixed(2),
            prefix: "£",
            suffix: "",
        },
        {
            name: "Total Profit",
            stat: Number(profitAndRoi.totalProfitLoss).toFixed(2),
            prefix: "£",
            suffix: "",
        },
        {
            name: "ROI",
            stat: Number(profitAndRoi.totalReturnOnInvestment).toFixed(
                profitAndRoi.totalReturnOnInvestment % 1 === 0 ? 0 : 2,
            ),
            prefix: "",
            suffix: "%",
        },
        {
            name: "Total Costs",
            stat: Number(profitAndRoi.totalCosts).toFixed(2),
            prefix: "£",
            suffix: "",
        },
    ];

    const productPageUrl = `/dashboard/products/${decodedId}`;

    return (
        <div className="rounded-lg bg-white px-4 py-5 shadow dark:bg-gray-800 sm:col-span-2 sm:col-start-3 sm:row-start-1 sm:p-6">
            <div className="text-sm font-semibold text-gray-600 dark:text-gray-300">
                Most Profitable Product
            </div>
            <a
                href={productPageUrl}
                className="mt-2 block text-lg font-semibold text-gray-900 hover:underline dark:text-white"
            >
                {itemName}
            </a>

            <div className="mt-4 flex max-w-screen-md flex-col md:flex-row">
                <div className="mb-4 w-full md:mb-0 md:mr-4 md:w-1/2">
                    <img
                        src={imageUrl}
                        alt={itemName}
                        className="h-full w-full rounded-lg object-cover"
                    />
                </div>
                <div className="grid w-full grid-cols-2 grid-rows-2 gap-4">
                    {stats.map((item) => (
                        <div
                            key={item.name}
                            className="flex flex-col justify-center rounded-lg border-2 bg-gray-200 p-4 dark:border-0 dark:bg-gray-700"
                        >
                            <div className="text-sm font-semibold text-gray-600 dark:text-gray-300">
                                {item.name}
                            </div>
                            <div
                                className={`truncate font-semibold tracking-tight ${getStatClass(item.name, parseFloat(item.stat))}`}
                            >
                                <span className="sm:text-lg md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl">
                                    {item.prefix}
                                    {item.stat}
                                    {item.suffix}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MostProfitableProductComponent;
