import { useContext } from "react";
import { AuthContext } from "@/contexts/AuthContext";
import MaintenancePage from "@/pages/MaintenancePage";
import CircularLoader from "@/components/loading/LoadingComponent";

interface GuardProps {
    children: React.ReactNode;
}

const MaintenanceGuard = ({ children }: GuardProps) => {
    console.log("Executing MaintenanceGuard");

    const { user, loading } = useContext(AuthContext);

    // Check if maintenance mode is active
    const isMaintenanceMode = import.meta.env.VITE_MAINTENANCE_MODE === "true";

    // Show loader while checking the user's status
    if (loading) {
        return <CircularLoader />;
    }

    // Render the maintenance page if maintenance mode is active and the user is not a superuser
    if (isMaintenanceMode && (!user || !user.isSuperuser)) {
        return <MaintenancePage />;
    }

    // Otherwise, render the children (allow access)
    return <>{children}</>;
};

export default MaintenanceGuard;
