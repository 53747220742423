import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "@/contexts/AuthContext";
import CircularLoader from "@/components/loading/LoadingComponent";

interface GuardProps {
    children: React.ReactNode;
}

const ValidEbayTokenGuard = ({ children }: GuardProps) => {
    const { ebayAccount, loading } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        // Ensure guard only runs after loading is completed
        if (!loading) {
            if (!ebayAccount || ebayAccount.invalidToken) {
                navigate("/reconnect-ebay");
            }
        }
    }, [ebayAccount, loading, navigate]);

    if (loading) {
        return <CircularLoader />;
    }

    return ebayAccount && !ebayAccount.invalidToken ? <>{children}</> : null;
};

export default ValidEbayTokenGuard;
