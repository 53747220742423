import React, { useEffect, useState } from "react";
import LogoComponent from "@/components/logo/LogoComponent";
import { useAuth } from "@/hooks/auth/useAuth";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation, ApolloError } from "@apollo/client";
import { INITIAL_UPDATE_EBAY_ACCOUNT_SETTINGS_MUTATION } from "@/graphql/mutations/settingsMutations";
import {
    GET_EBAY_ACCOUNT_SETTINGS_QUERY,
    GET_VAT_SETTINGS_QUERY,
} from "@/graphql/queries/ebay/settings/settingsQueries";
import CircularLoader from "@/components/loading/LoadingComponent";
import { Alert } from "@/components/dashboard/general/AlertComponent";
import { formatDate } from "date-fns";

const InitialSettingsPage: React.FC = () => {
    console.log("Executing InitialSettingsPage");
    const { logoutUser, ebayAccount, fetchUserData } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (ebayAccount && ebayAccount.initialSettingsCompleted) {
            navigate("/dashboard");
        }
    }, [ebayAccount, navigate]);

    const {
        data: ebaySettingsData,
        loading: ebaySettingsLoading,
        error: ebaySettingsError,
    } = useQuery(GET_EBAY_ACCOUNT_SETTINGS_QUERY);

    const {
        data: vatSettingsData,
        loading: vatSettingsLoading,
        error: vatSettingsError,
    } = useQuery(GET_VAT_SETTINGS_QUERY);

    const [updateEbayAccountSettings] = useMutation(
        INITIAL_UPDATE_EBAY_ACCOUNT_SETTINGS_MUTATION,
        {
            onCompleted: () => {
                console.log("Settings updated");
            },
        },
    );

    const [matchPostagePriceWithCost, setMatchPostagePriceWithCost] = useState<
        boolean | null
    >(null);

    const [defaultPackingCostPerItem, setDefaultPackingCostPerItem] = useState<
        number | null
    >(null);
    const [vatRegistered, setVatRegistered] = useState<boolean>(
        vatSettingsData?.vatSettingsHistory?.isVatRegistered ?? false,
    );
    const [vatType, setVatType] = useState<string | null>(null);
    const [vatRate, setVatRate] = useState<number | null>(null);
    const [vatStartDate, setVatStartDate] = useState<string>(
        vatSettingsData?.vatSettingsHistory?.vatStartDate ??
            formatDate(new Date(), "yyyy-MM-dd"),
    );
    const [canClaimVatOnCost, setCanClaimVatOnCosts] = useState<boolean | null>(
        null,
    );
    const [alertMessage, setAlertMessage] = useState<string | null>(null);

    useEffect(() => {
        if (ebaySettingsData && ebaySettingsData.ebayAccountSettings) {
            setMatchPostagePriceWithCost(
                ebaySettingsData.ebayAccountSettings.matchPostagePriceWithCost,
            );
            setDefaultPackingCostPerItem(
                ebaySettingsData.ebayAccountSettings.defaultPackingCostPerItem,
            );
        }

        if (vatSettingsData && vatSettingsData.vatSettingsHistory) {
            setVatRegistered(
                vatSettingsData.vatSettingsHistory.isVatRegistered,
            );
            setVatType(vatSettingsData.vatSettingsHistory.vatType);
            setVatRate(vatSettingsData.vatSettingsHistory.vatRate);
            setVatStartDate(vatSettingsData.vatSettingsHistory.vatStartDate);
            setCanClaimVatOnCosts(
                vatSettingsData.vatSettingsHistory.canClaimVatOnCost,
            );
        }
    }, [ebaySettingsData, vatSettingsData]);

    const handleSave = async () => {
        try {
            await updateEbayAccountSettings({
                variables: {
                    matchPostagePriceWithCost,
                    defaultPackingCostPerItem,
                    canClaimVatOnCosts: canClaimVatOnCost,
                    isVatRegistered: vatRegistered,
                    vatRate,
                    vatStartDate,
                    vatType,
                },
            });

            fetchUserData();
            navigate("/dashboard");
        } catch (error) {
            console.error("Error updating settings: ", error);

            if (error instanceof ApolloError) {
                const errorMessage = error.message;

                if (
                    errorMessage.includes(
                        "VAT start date cannot be earlier or equal to the latest VAT start date",
                    )
                ) {
                    setAlertMessage(
                        "The VAT start date must be later than the most recent VAT start date. Please update the value.",
                    );
                } else {
                    setAlertMessage(
                        "An error occurred while updating settings. Please try again.",
                    );
                }
            } else {
                setAlertMessage(
                    "Unexpected error occurred. Please contact support.",
                );
            }
        }
    };

    if (ebaySettingsLoading || vatSettingsLoading) return <CircularLoader />;
    if (ebaySettingsError || vatSettingsError)
        return (
            <p>
                Error: {ebaySettingsError?.message || vatSettingsError?.message}
            </p>
        );

    return (
        <div className="flex min-h-full flex-1 flex-col px-2 py-4 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <LogoComponent className="mx-auto h-20 w-auto" />
                <h2 className="mt-2 text-center text-2xl font-bold leading-9 tracking-tight dark:text-white">
                    Set Your Account Preferences
                </h2>
                <p className="mt-2 text-center text-sm text-gray-600 dark:text-gray-400">
                    Customize your FlipLytics experience by adjusting your
                    settings below.
                </p>
            </div>
            <div className="mt-8 flex justify-center">
                <div className="relative mx-8 justify-center rounded-lg bg-white p-6 shadow-md dark:bg-gray-800">
                    <h2 className="mb-4 text-xl font-semibold dark:text-white">
                        Account Settings
                    </h2>
                    <div className="space-y-4">
                        <div className="flex flex-col border-b border-gray-600 pb-4 md:flex-row md:justify-between">
                            <div className="flex w-full flex-col md:w-3/4">
                                <span className="font-medium dark:text-gray-400">
                                    Match Postage Costs
                                </span>
                                <span className="text-sm dark:text-gray-400">
                                    If you enable this setting, FlipLytics will
                                    automatically match the costs that you paid
                                    for postage with the costs that you charged
                                    your customers, which we get from eBay
                                    directly.
                                </span>
                            </div>
                            <div className="w-full text-right md:w-1/4">
                                <select
                                    value={
                                        matchPostagePriceWithCost ? "Yes" : "No"
                                    }
                                    onChange={(e) =>
                                        setMatchPostagePriceWithCost(
                                            e.target.value === "Yes",
                                        )
                                    }
                                    className="form-select mt-2 w-full rounded-md dark:bg-gray-700 dark:text-white md:mt-0 md:w-36"
                                >
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                        </div>
                        <div className="flex flex-col border-b border-gray-600 pb-4 md:flex-row md:justify-between">
                            <div className="flex w-full flex-col md:w-3/4">
                                <span className="font-medium dark:text-gray-400">
                                    Default Packing Cost Per Item
                                </span>
                                <span className="text-sm dark:text-gray-400">
                                    This is the default cost that you incur for
                                    packing each item that you sell.
                                </span>
                            </div>
                            <div className="w-full text-right dark:text-white md:w-1/2">
                                <input
                                    type="number"
                                    value={defaultPackingCostPerItem || ""}
                                    onChange={(e) =>
                                        setDefaultPackingCostPerItem(
                                            parseFloat(e.target.value),
                                        )
                                    }
                                    className="mt-2 w-full rounded-md dark:bg-gray-700 dark:text-white md:mt-0 md:w-36"
                                />
                            </div>
                        </div>
                        <div className="flex flex-col border-b border-gray-600 pb-4 md:flex-row md:justify-between">
                            <div className="flex w-full flex-col md:w-3/4">
                                <span className="font-medium dark:text-gray-400">
                                    VAT Registered
                                </span>
                                <span className="text-sm dark:text-gray-400">
                                    Are you VAT registered?
                                </span>
                            </div>
                            <div className="w-full text-right md:w-1/4">
                                <select
                                    value={vatRegistered ? "Yes" : "No"}
                                    onChange={(e) =>
                                        setVatRegistered(
                                            e.target.value === "Yes",
                                        )
                                    }
                                    className="form-select mt-2 w-full rounded-md dark:bg-gray-700 dark:text-white md:mt-0 md:w-36"
                                >
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                        </div>
                        {vatRegistered && (
                            <div className="flex flex-col border-b border-gray-600 pb-4 md:flex-row md:justify-between">
                                <div className="flex w-full flex-col md:w-3/4">
                                    <span className="font-medium dark:text-gray-400">
                                        VAT Scheme
                                    </span>
                                    <span className="text-sm dark:text-gray-400">
                                        Which VAT scheme are you on? (Standard
                                        or Flat Rate)
                                    </span>
                                </div>
                                <div className="w-full text-right md:w-1/4">
                                    <select
                                        value={vatType?.toLowerCase() ?? "None"}
                                        onChange={(e) =>
                                            setVatType(e.target.value)
                                        }
                                        className="form-select mt-2 w-full rounded-md dark:bg-gray-700 dark:text-white md:mt-0 md:w-36"
                                    >
                                        <option value="None">None</option>
                                        <option value="standard">
                                            Standard
                                        </option>
                                        <option value="flat_rate">
                                            Flat Rate
                                        </option>
                                    </select>
                                </div>
                            </div>
                        )}
                        {vatRegistered &&
                            vatType?.toLowerCase() === "flat_rate" && (
                                <div className="flex flex-col border-b border-gray-600 pb-4 md:flex-row md:justify-between">
                                    <div className="flex w-full flex-col md:w-1/2">
                                        <span className="font-medium dark:text-gray-400">
                                            VAT Rate
                                        </span>
                                        <span className="text-sm dark:text-gray-400">
                                            What is your VAT rate in percentage?
                                            (eg 6.5%)
                                        </span>
                                    </div>
                                    <div className="w-full text-right dark:text-white md:w-1/2">
                                        <input
                                            type="number"
                                            value={vatRate || ""}
                                            onChange={(e) =>
                                                setVatRate(
                                                    parseFloat(e.target.value),
                                                )
                                            }
                                            className="mt-2 w-full rounded-md dark:bg-gray-700 dark:text-white md:mt-0 md:w-36"
                                        />
                                    </div>
                                </div>
                            )}
                        {vatRegistered && (
                            <div className="flex flex-col border-b border-gray-600 pb-4 md:flex-row md:justify-between">
                                <div className="flex w-full flex-col md:w-3/4">
                                    <span className="font-medium dark:text-gray-400">
                                        VAT Start Date
                                    </span>
                                    <span className="text-sm dark:text-gray-400">
                                        When did you start charging VAT or when
                                        did your vat rate/scheme change?
                                    </span>
                                </div>
                                <div className="w-full text-right md:w-1/4">
                                    <input
                                        type="date"
                                        value={vatStartDate || ""}
                                        onChange={(e) =>
                                            setVatStartDate(e.target.value)
                                        }
                                        className="mt-2 w-full rounded-md dark:bg-gray-700 dark:text-white md:mt-0 md:w-36"
                                    />
                                </div>
                            </div>
                        )}
                        {vatRegistered &&
                            vatType?.toLowerCase() === "standard" && (
                                <div className="flex flex-col border-b border-gray-600 pb-4 md:flex-row md:justify-between">
                                    <div className="flex w-full flex-col md:w-3/4">
                                        <span className="font-medium dark:text-gray-400">
                                            Can Claim VAT on Costs
                                        </span>
                                        <span className="text-sm dark:text-gray-400">
                                            Can you claim VAT on costs?
                                        </span>
                                    </div>
                                    <div className="w-full text-right md:w-1/4">
                                        <select
                                            value={
                                                canClaimVatOnCost ? "Yes" : "No"
                                            }
                                            onChange={(e) =>
                                                setCanClaimVatOnCosts(
                                                    e.target.value === "Yes",
                                                )
                                            }
                                            className="form-select mt-2 w-full rounded-md dark:bg-gray-700 dark:text-white md:mt-0 md:w-36"
                                        >
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                </div>
                            )}
                    </div>
                    {alertMessage && (
                        <Alert
                            message={alertMessage}
                            onClose={() => setAlertMessage(null)}
                        />
                    )}
                    <div className="mt-4 flex justify-end space-x-2">
                        <button
                            onClick={handleSave}
                            className="rounded-md bg-brand px-4 py-2 text-white"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
            <div className="sm:w mt-10 sm:mx-auto">
                <p className="text-center text-sm dark:text-gray-400">
                    Need help?{" "}
                    <a
                        href="https://fliplytics.co.uk/contact"
                        className="hover:text-brand-light font-semibold text-brand"
                    >
                        Contact our support team
                    </a>
                    .
                </p>
                <p className="mt-3 text-center text-sm dark:text-gray-400">
                    <a
                        href="#"
                        onClick={logoutUser}
                        className="hover:text-brand-light font-semibold text-brand"
                    >
                        Sign out
                    </a>
                </p>
            </div>
        </div>
    );
};

export default InitialSettingsPage;
