import { useQuery, useMutation } from "@apollo/client";
import { useState, useEffect } from "react";
import { GET_ACCOUNT_SETTINGS_QUERY } from "@/graphql/queries/accountsQueries";
import { GET_EBAY_ACCOUNT_SETTINGS_QUERY } from "@/graphql/queries/ebay/settings/settingsQueries";
import { GET_VAT_SETTINGS_QUERY } from "@/graphql/queries/ebay/settings/settingsQueries";
import {
    UPDATE_EBAY_ACCOUNT_SETTINGS_MUTATION,
    UPDATE_USER_MUTATION,
    UPDATE_VAT_SETTINGS_MUTATION,
} from "@/graphql/mutations/settingsMutations";
import { CREATE_MANAGE_SUBSCRIPTION_SESSION_MUTATION } from "@/graphql/mutations/paymentMutations";
import InitialsComponent from "@/components/dashboard/general/svgs/InitialsComponent";
import CircularLoader from "@/components/loading/LoadingComponent";
import PasswordUpdateModal from "@/components/dashboard/dash/account/modals/PasswordUpdateModal";
import { useNavigate } from "react-router-dom";
import { formatDate } from "@/helpers/common/formatDate";
import { Alert } from "@/components/dashboard/general/AlertComponent";
import { ApolloError } from "@apollo/client";

export default function AccountSettingsPage() {
    const navigate = useNavigate();
    const { data, loading, error } = useQuery(GET_ACCOUNT_SETTINGS_QUERY);
    const {
        data: ebaySettingsData,
        loading: ebaySettingsLoading,
        error: ebaySettingsError,
    } = useQuery(GET_EBAY_ACCOUNT_SETTINGS_QUERY);

    const {
        data: vatSettingsData,
        loading: vatSettingsLoading,
        error: vatSettingsError,
    } = useQuery(GET_VAT_SETTINGS_QUERY);

    const [updateEbayAccountSettings] = useMutation(
        UPDATE_EBAY_ACCOUNT_SETTINGS_MUTATION,
        {
            onCompleted: () => {
                setEditMode(false);
            },
        },
    );

    const [updateVatSettings] = useMutation(UPDATE_VAT_SETTINGS_MUTATION, {
        onCompleted: () => {
            setEditVatMode(false);
        },
    });

    const [updateUser] = useMutation(UPDATE_USER_MUTATION, {
        onCompleted: () => {
            setEditAccountMode(false);
        },
    });

    const [createManageSubscriptionSession] = useMutation(
        CREATE_MANAGE_SUBSCRIPTION_SESSION_MUTATION,
        {
            onCompleted: (data) => {
                const portalUrl =
                    data.createManageSubscriptionSession.customerPortalUrl;
                if (portalUrl) {
                    window.location.href = portalUrl;
                }
            },
            onError: (error) => {
                console.error("Error creating subscription session:", error);
            },
        },
    );

    const [editMode, setEditMode] = useState(false);
    const [editAccountMode, setEditAccountMode] = useState(false); // State for editing account details
    const [editVatMode, setEditVatMode] = useState(false); // State for editing VAT settings
    const [passwordModalOpen, setPasswordModalOpen] = useState(false); // State for password modal
    const [firstName, setFirstName] = useState<string>(""); // First name state
    const [lastName, setLastName] = useState<string>(""); // Last name state
    const [email, setEmail] = useState<string>(""); // Email state
    const [matchPostagePriceWithCost, setMatchPostagePriceWithCost] = useState<
        boolean | null
    >(null);
    const [defaultPackingCostPerItem, setDefaultPackingCostPerItem] = useState<
        number | null
    >(null);

    const [vatRegistered, setVatRegistered] = useState<boolean | null>(null);
    const [vatType, setVatType] = useState<string | null>(null);
    const [vatRate, setVatRate] = useState<number | null>(null);
    const [vatStartDate, setVatStartDate] = useState<string | null>(null);
    const [canClaimVatOnCost, setCanClaimVatOnCosts] = useState<boolean | null>(
        null,
    );
    const [alertMessage, setAlertMessage] = useState<string | null>(null);

    useEffect(() => {
        if (ebaySettingsData && ebaySettingsData.ebayAccountSettings) {
            setMatchPostagePriceWithCost(
                ebaySettingsData.ebayAccountSettings.matchPostagePriceWithCost,
            );
            setDefaultPackingCostPerItem(
                ebaySettingsData.ebayAccountSettings.defaultPackingCostPerItem,
            );
        }

        if (vatSettingsData && vatSettingsData.vatSettingsHistory) {
            setVatRegistered(
                vatSettingsData.vatSettingsHistory.isVatRegistered,
            );
            setVatType(vatSettingsData.vatSettingsHistory.vatType);
            setVatRate(vatSettingsData.vatSettingsHistory.vatRate);
            setVatStartDate(vatSettingsData.vatSettingsHistory.vatStartDate);
            setCanClaimVatOnCosts(
                vatSettingsData.vatSettingsHistory.canClaimVatOnCost,
            );
        }

        if (data && data.user) {
            setFirstName(data.user.firstName); // Set first name
            setLastName(data.user.lastName); // Set last name
            setEmail(data.user.email); // Set email
        }
    }, [ebaySettingsData, vatSettingsData, data]);

    if (loading || ebaySettingsLoading || vatSettingsLoading)
        return <CircularLoader />;
    if (error || ebaySettingsError || vatSettingsError)
        return (
            <p>
                Error:{" "}
                {error?.message ||
                    ebaySettingsError?.message ||
                    vatSettingsError?.message}
            </p>
        );

    const { user, subscription, tierPricing, totalStats, billingCycle } = data;

    const { ebayAccount, dateJoined } = user;
    const { ebayUserId: storeName, storeType } = ebayAccount;

    const name = `${firstName} ${lastName}`;
    const dateSignedUp = new Date(dateJoined).toLocaleDateString();
    const storeUrl = "https://www.ebay.co.uk/usr/" + storeName;

    const price =
        billingCycle === "MONTHLY"
            ? tierPricing.monthlyPrice
            : tierPricing.yearlyPrice;
    const currency_name = tierPricing.currency;
    const currencySymbol = (currency: string) => {
        switch (currency.toUpperCase()) {
            case "USD":
                return "$";
            case "EUR":
                return "€";
            case "GBP":
                return "£";
            default:
                return currency;
        }
    };

    const price_suffix = billingCycle === "MONTHLY" ? "pm" : "py";
    const fullPrice = `${currencySymbol(currency_name)}${price}${price_suffix}`;

    const stats = [
        {
            name: "Total Active Listings",
            value: totalStats.totalActiveListings,
        },
        {
            name: "Total Ended Listings",
            value: totalStats.totalEndedListings,
        },
        {
            name: "Total Products Sold",
            value: totalStats.totalProductsSold,
        },
        {
            name: "Total VAT Collected",
            value: `£${totalStats.totalVatCollected}`,
        },
        {
            name: "Total VAT Reclaimable",
            value: `£${totalStats.totalVatReclaimable}`,
        },
    ];

    const handleSettingsEdit = () => {
        setEditMode(true);
    };

    const handleAccountDetailsEdit = () => {
        setEditAccountMode(true); // Trigger edit mode for account details
    };

    const handleVatSettingsEdit = () => {
        setEditVatMode(true);
    };

    const handleAccountSave = async () => {
        // Save account details function
        await updateUser({
            variables: {
                email,
                firstName,
                lastName,
            },
        });
        navigate(0); // Refresh the page to reflect changes / redirect to email verification page if email was changed
    };

    const handleCancelAccountEdit = () => {
        setEditAccountMode(false); // Cancel edit mode
    };

    const handleCancel = () => {
        setEditMode(false);
    };

    const handleCancelVatSettingsEdit = () => {
        setEditVatMode(false);
    };

    const handleVatSettingsSave = async () => {
        try {
            console.log("VAT Start Date: ", vatStartDate);
            console.log("VAT Rate: ", vatRate);
            console.log("VAT Type: ", vatType);
            console.log("VAT Registered: ", vatRegistered);
            console.log("Can Claim VAT on Costs: ", canClaimVatOnCost);

            await updateVatSettings({
                variables: {
                    isVatRegistered: vatRegistered,
                    vatType,
                    vatRate,
                    vatStartDate: vatStartDate || null,
                    canClaimVatOnCosts: canClaimVatOnCost,
                },
            });
        } catch (error) {
            console.error("Error updating settings: ", error);

            if (error instanceof ApolloError) {
                const errorMessage = error.message;

                if (
                    errorMessage.includes(
                        "VAT start date cannot be earlier or equal to the latest VAT start date",
                    )
                ) {
                    setAlertMessage(
                        "The VAT start date must be later than the most recent VAT start date. Please update the value.",
                    );
                } else if (
                    errorMessage.includes(
                        "No changes to your current VAT settings have been detected.",
                    )
                ) {
                    setAlertMessage(
                        "No changes to your current VAT settings have been detected.",
                    );
                } else {
                    setAlertMessage(
                        "An error occurred while updating settings. Please try again.",
                    );
                }
            } else {
                setAlertMessage(
                    "Unexpected error occurred. Please contact support.",
                );
            }
        }
    };

    const handleSave = async () => {
        await updateEbayAccountSettings({
            variables: {
                matchPostagePriceWithCost,
                defaultPackingCostPerItem,
            },
        });
    };

    const changePassword = () => {
        setPasswordModalOpen(true); // Open password modal
    };

    const handleManageSubscription = (e: React.MouseEvent) => {
        e.preventDefault(); // Prevent page refresh
        createManageSubscriptionSession();
    };

    const subscriptionStatusDetails =
        subscription.status === "active" ? (
            <>
                <span className="dark:text-gray-400">Next Payment</span>
                <span className="dark:text-white">
                    {new Date(
                        subscription.currentPeriodEnd,
                    ).toLocaleDateString()}
                </span>
            </>
        ) : subscription.status === "trialing" ? (
            <>
                <span className="dark:text-gray-400">Trial Ends</span>
                <span className="dark:text-white">
                    {new Date(subscription.trialEnd).toLocaleDateString()}
                </span>
            </>
        ) : null;

    return (
        <div className="min-h-screen py-6 dark:bg-gray-900">
            <div className="container px-4">
                <h3 className="mb-4 text-base font-semibold leading-6 text-gray-900 dark:text-white">
                    Your FlipLytics Account
                </h3>
                <div className="grid grid-cols-1 gap-6 lg:grid-cols-6">
                    <div className="relative col-span-4 rounded-lg bg-white p-6 shadow-md dark:bg-gray-800 lg:col-span-2">
                        {!editAccountMode && (
                            <button
                                onClick={handleAccountDetailsEdit} // Edit account details button
                                className="absolute right-4 top-4 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="h-5 w-5"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M16.862 3.487a1.125 1.125 0 011.588 0l2.062 2.062a1.125 1.125 0 010 1.588L7.125 20.525l-4.5.75.75-4.5L16.862 3.487z"
                                    />
                                </svg>
                            </button>
                        )}
                        <div className="mb-6 flex items-center">
                            <InitialsComponent
                                name={name}
                                className="flex items-center justify-center rounded-full"
                            />
                            <h1 className="ml-4 text-3xl font-semibold dark:text-white">
                                {name}
                            </h1>
                        </div>

                        <div className="space-y-4">
                            <div className="flex justify-between border-b border-gray-600 pb-2">
                                <span className="dark:text-gray-400">
                                    Email
                                </span>
                                {editAccountMode ? ( // Show input fields when editing
                                    <div className="relative w-3/4">
                                        <input
                                            type="email"
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            className="form-input w-full rounded-md border dark:border-gray-600 dark:bg-gray-700 dark:text-white"
                                        />
                                    </div>
                                ) : (
                                    <span className="dark:text-white">
                                        {email}
                                    </span>
                                )}
                            </div>

                            <div className="flex justify-between border-b border-gray-600 pb-2">
                                <span className="dark:text-gray-400">
                                    First Name
                                </span>
                                {editAccountMode ? (
                                    <input
                                        type="text"
                                        value={firstName}
                                        onChange={(e) =>
                                            setFirstName(e.target.value)
                                        }
                                        className="form-input rounded-md border dark:border-gray-600 dark:bg-gray-700 dark:text-white"
                                    />
                                ) : (
                                    <span className="dark:text-white">
                                        {firstName}
                                    </span>
                                )}
                            </div>
                            <div className="flex justify-between border-b border-gray-600 pb-2">
                                <span className="dark:text-gray-400">
                                    Last Name
                                </span>
                                {editAccountMode ? (
                                    <input
                                        type="text"
                                        value={lastName}
                                        onChange={(e) =>
                                            setLastName(e.target.value)
                                        }
                                        className="form-input rounded-md border dark:border-gray-600 dark:bg-gray-700 dark:text-white"
                                    />
                                ) : (
                                    <span className="dark:text-white">
                                        {lastName}
                                    </span>
                                )}
                            </div>
                            <div className="flex justify-between border-b border-gray-600 pb-2">
                                <span className="dark:text-gray-400">
                                    eBay user ID
                                </span>
                                <span className="text-brand">
                                    <a
                                        href={storeUrl}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {storeName}
                                    </a>
                                </span>
                            </div>
                            <div className="flex justify-between border-b border-gray-600 pb-2">
                                <span className="dark:text-gray-400">
                                    Password
                                </span>
                                <button
                                    onClick={changePassword} // Open password modal
                                    className="text-brand"
                                >
                                    Change your password &raquo;
                                </button>
                            </div>
                            <div className="flex justify-between border-b border-gray-600 pb-2">
                                <span className="dark:text-gray-400">
                                    Joined FlipLytics
                                </span>
                                <span className="dark:text-white">
                                    {dateSignedUp}
                                </span>
                            </div>
                        </div>

                        {editAccountMode && ( // Show save and cancel buttons if in edit mode
                            <div className="mt-4 flex justify-end space-x-2">
                                <button
                                    onClick={handleCancelAccountEdit}
                                    className="rounded-lg bg-gray-300 px-4 py-2 hover:bg-gray-200 dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleAccountSave} // Call save function
                                    className="rounded-lg bg-brand px-4 py-2 text-white"
                                >
                                    Save
                                </button>
                            </div>
                        )}
                    </div>

                    <div className="col-span-4 rounded-lg bg-white p-6 shadow-md dark:bg-gray-800 lg:col-span-2">
                        <h2 className="mb-4 text-xl font-semibold dark:text-white">
                            Subscription Details
                        </h2>
                        <div className="space-y-4">
                            <div className="flex justify-between border-b border-gray-600 pb-2">
                                <span className="dark:text-gray-400">Plan</span>
                                <span className="dark:text-white">
                                    {storeType.charAt(0).toUpperCase() +
                                        storeType.slice(1).toLowerCase()}
                                </span>
                            </div>
                            <div className="flex justify-between border-b border-gray-600 pb-2">
                                <span className="dark:text-gray-400">
                                    Status
                                </span>
                                <span className="dark:text-white">
                                    {subscription.status
                                        .charAt(0)
                                        .toUpperCase() +
                                        subscription.status
                                            .slice(1)
                                            .toLowerCase()}
                                </span>
                            </div>
                            <div className="flex justify-between border-b border-gray-600 pb-2">
                                {subscriptionStatusDetails}
                            </div>
                            <div className="flex justify-between border-b border-gray-600 pb-2">
                                <span className="dark:text-gray-400">
                                    Price
                                </span>
                                <span className="dark:text-white">
                                    {fullPrice}
                                </span>
                            </div>
                            <div className="flex justify-between border-b border-gray-600 pb-2">
                                <button
                                    onClick={handleManageSubscription}
                                    className="text-brand"
                                    type="button"
                                >
                                    Manage Subscription
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="col-span-4 rounded-lg bg-white p-6 shadow-md dark:bg-gray-800 lg:col-span-2">
                        <h2 className="mb-4 text-xl font-semibold dark:text-white">
                            Your Stats
                        </h2>
                        <div className="space-y-4">
                            {stats.map((stat, index) => (
                                <div
                                    key={index}
                                    className="flex justify-between border-b border-gray-600 pb-2"
                                >
                                    <span className="dark:text-gray-400">
                                        {stat.name}
                                    </span>
                                    <span className="dark:text-white">
                                        {stat.value}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="relative col-span-4 rounded-lg bg-white p-6 shadow-md dark:bg-gray-800 lg:col-span-3">
                        {!editMode && (
                            <button
                                onClick={handleSettingsEdit}
                                className="absolute right-4 top-4 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="h-5 w-5"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M16.862 3.487a1.125 1.125 0 011.588 0l2.062 2.062a1.125 1.125 0 010 1.588L7.125 20.525l-4.5.75.75-4.5L16.862 3.487z"
                                    />
                                </svg>
                            </button>
                        )}
                        <h2 className="mb-4 text-xl font-semibold dark:text-white">
                            Account Settings
                        </h2>
                        <div className="space-y-4">
                            <div className="flex justify-between border-b border-gray-600 pb-4">
                                <div className="flex w-3/4 flex-col">
                                    <span className="font-medium dark:text-gray-400">
                                        Match Postage Costs
                                    </span>
                                    <span className="text-sm dark:text-gray-400">
                                        If you enable this setting, FlipLytics
                                        will automatically match the costs that
                                        you paid for postage with the costs that
                                        you charged your customers, which we get
                                        from eBay directly.
                                    </span>
                                </div>
                                <div className="w-1/4 text-right dark:text-white">
                                    {editMode ? (
                                        <select
                                            value={
                                                matchPostagePriceWithCost ===
                                                true
                                                    ? "Yes"
                                                    : "No"
                                            }
                                            onChange={(e) =>
                                                setMatchPostagePriceWithCost(
                                                    e.target.value === "Yes",
                                                )
                                            }
                                            className="w-full max-w-[9rem] rounded-md border px-2 py-1 dark:border-gray-600 dark:bg-gray-700 dark:text-white"
                                        >
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    ) : matchPostagePriceWithCost !== null ? (
                                        matchPostagePriceWithCost ? (
                                            "Yes"
                                        ) : (
                                            "No"
                                        )
                                    ) : (
                                        <CircularLoader />
                                    )}
                                </div>
                            </div>
                            <div className="flex justify-between border-b border-gray-600 pb-4">
                                <div className="flex w-3/4 flex-col">
                                    <span className="font-medium dark:text-gray-400">
                                        Default Packing Cost Per Item
                                    </span>
                                    <span className="text-sm dark:text-gray-400">
                                        This is the default cost (£) that you
                                        incur for packing each item that you
                                        sell.
                                    </span>
                                </div>
                                <div className="w-1/4 text-right dark:text-white">
                                    {editMode ? (
                                        <input
                                            type="number"
                                            value={
                                                defaultPackingCostPerItem ?? ""
                                            }
                                            onChange={(e) =>
                                                setDefaultPackingCostPerItem(
                                                    e.target.value
                                                        ? parseFloat(
                                                              e.target.value,
                                                          )
                                                        : null,
                                                )
                                            }
                                            className="w-full max-w-[6rem] rounded-md border px-2 py-1 dark:border-gray-600 dark:bg-gray-700 dark:text-white"
                                        />
                                    ) : defaultPackingCostPerItem !== null ? (
                                        `£${defaultPackingCostPerItem}`
                                    ) : (
                                        "N/A"
                                    )}
                                </div>
                            </div>
                        </div>
                        {editMode && (
                            <div className="mt-4 flex justify-end space-x-2">
                                <button
                                    onClick={handleCancel}
                                    className="rounded-lg bg-gray-300 px-4 py-2 hover:bg-gray-200 dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleSave}
                                    className="rounded-lg bg-brand px-4 py-2 text-white"
                                >
                                    Save
                                </button>
                            </div>
                        )}
                    </div>

                    <div className="relative col-span-4 rounded-lg bg-white p-6 shadow-md dark:bg-gray-800 lg:col-span-3">
                        {!editVatMode && (
                            <button
                                onClick={handleVatSettingsEdit}
                                className="absolute right-4 top-4 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="h-5 w-5"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M16.862 3.487a1.125 1.125 0 011.588 0l2.062 2.062a1.125 1.125 0 010 1.588L7.125 20.525l-4.5.75.75-4.5L16.862 3.487z"
                                    />
                                </svg>
                            </button>
                        )}
                        <h2 className="mb-4 text-xl font-semibold dark:text-white">
                            VAT Settings
                        </h2>
                        <div className="space-y-4">
                            <div className="flex justify-between border-b border-gray-600 pb-4">
                                <div className="flex w-3/4 flex-col">
                                    <span className="font-medium dark:text-gray-400">
                                        VAT Registered
                                    </span>
                                    <span className="text-sm dark:text-gray-400">
                                        Are you VAT registered?
                                    </span>
                                </div>
                                <div className="w-1/4 text-right dark:text-white">
                                    {editVatMode ? (
                                        <select
                                            value={
                                                vatRegistered === true
                                                    ? "Yes"
                                                    : "No"
                                            } // Map boolean to "Yes"/"No"
                                            onChange={(e) =>
                                                setVatRegistered(
                                                    e.target.value === "Yes",
                                                )
                                            } // Explicitly map "Yes" to true and "No" to false
                                            className="w-full max-w-[9rem] rounded-md border px-2 py-1 dark:border-gray-600 dark:bg-gray-700 dark:text-white"
                                        >
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    ) : vatRegistered !== null ? (
                                        vatRegistered ? (
                                            "Yes"
                                        ) : (
                                            "No"
                                        )
                                    ) : (
                                        <CircularLoader />
                                    )}
                                </div>
                            </div>
                            {vatRegistered && (
                                <div className="flex justify-between border-b border-gray-600 pb-4">
                                    <div className="flex w-3/4 flex-col">
                                        <span className="font-medium dark:text-gray-400">
                                            VAT Scheme
                                        </span>
                                        <span className="text-sm dark:text-gray-400">
                                            Which VAT scheme are you on?
                                            (Standard or Flat Rate)
                                        </span>
                                    </div>
                                    <div className="w-1/4 text-right dark:text-white">
                                        {editVatMode ? (
                                            <select
                                                value={
                                                    vatType?.toLowerCase() ??
                                                    "None"
                                                }
                                                onChange={(e) =>
                                                    setVatType(e.target.value)
                                                }
                                                className="w-full max-w-[9rem] rounded-md border px-2 py-1 dark:border-gray-600 dark:bg-gray-700 dark:text-white"
                                            >
                                                <option value="None">
                                                    None
                                                </option>
                                                <option value="standard">
                                                    Standard
                                                </option>
                                                <option value="flat_rate">
                                                    Flat Rate
                                                </option>
                                            </select>
                                        ) : // Convert vatType to human readable format (eg Standard if standard, Flat Rate if flat_rate)
                                        vatType !== null ? (
                                            vatType.toLowerCase() ===
                                            "standard" ? (
                                                "Standard"
                                            ) : (
                                                "Flat Rate"
                                            )
                                        ) : (
                                            "N/A"
                                        )}
                                    </div>
                                </div>
                            )}
                            {vatRegistered &&
                                vatType?.toLowerCase() === "flat_rate" && (
                                    <div className="flex justify-between border-b border-gray-600 pb-4">
                                        <div className="flex w-3/4 flex-col">
                                            <span className="font-medium dark:text-gray-400">
                                                VAT Rate
                                            </span>
                                            <span className="text-sm dark:text-gray-400">
                                                What is your VAT rate in
                                                percentage? (eg 6.5%)
                                            </span>
                                        </div>
                                        <div className="w-1/4 text-right dark:text-white">
                                            {editVatMode ? (
                                                <input
                                                    type="number"
                                                    value={vatRate ?? ""}
                                                    onChange={(e) =>
                                                        setVatRate(
                                                            e.target.value
                                                                ? parseFloat(
                                                                      e.target
                                                                          .value,
                                                                  )
                                                                : null,
                                                        )
                                                    }
                                                    className="w-full max-w-[9rem] rounded-md border px-2 py-1 dark:border-gray-600 dark:bg-gray-700 dark:text-white"
                                                />
                                            ) : vatRate !== null ? (
                                                `${vatRate}%`
                                            ) : (
                                                "N/A"
                                            )}
                                        </div>
                                    </div>
                                )}
                            {vatRegistered && (
                                <div className="flex justify-between border-b border-gray-600 pb-4">
                                    <div className="flex w-3/4 flex-col">
                                        <span className="font-medium dark:text-gray-400">
                                            VAT Start Date
                                        </span>
                                        <span className="text-sm dark:text-gray-400">
                                            When did you start charging VAT or
                                            when did your vat rate/scheme
                                            change?
                                        </span>
                                    </div>
                                    <div className="w-1/4 text-right dark:text-white">
                                        {editVatMode ? (
                                            <input
                                                type="date"
                                                value={vatStartDate ?? ""}
                                                onChange={(e) =>
                                                    setVatStartDate(
                                                        e.target.value,
                                                    )
                                                }
                                                className="w-full max-w-[9rem] rounded-md border px-2 py-1 dark:border-gray-600 dark:bg-gray-700 dark:text-white"
                                            />
                                        ) : vatStartDate !== null ? (
                                            formatDate(vatStartDate)
                                        ) : (
                                            "N/A"
                                        )}
                                    </div>
                                </div>
                            )}
                            {vatRegistered &&
                                vatType?.toLowerCase() === "standard" && (
                                    <div className="flex justify-between border-b border-gray-600 pb-4">
                                        <div className="flex w-3/4 flex-col">
                                            <span className="font-medium dark:text-gray-400">
                                                Can Claim VAT on Costs
                                            </span>
                                            <span className="text-sm dark:text-gray-400">
                                                Can you claim VAT on costs?
                                            </span>
                                        </div>
                                        <div className="w-1/4 text-right dark:text-white">
                                            {editVatMode ? (
                                                <select
                                                    value={
                                                        canClaimVatOnCost ===
                                                        true
                                                            ? "Yes"
                                                            : "No"
                                                    }
                                                    onChange={(e) =>
                                                        setCanClaimVatOnCosts(
                                                            e.target.value ===
                                                                "Yes",
                                                        )
                                                    }
                                                    className="w-full max-w-[9rem] rounded-md border px-2 py-1 dark:border-gray-600 dark:bg-gray-700 dark:text-white"
                                                >
                                                    <option value="Yes">
                                                        Yes
                                                    </option>
                                                    <option value="No">
                                                        No
                                                    </option>
                                                </select>
                                            ) : canClaimVatOnCost !== null ? (
                                                canClaimVatOnCost ? (
                                                    "Yes"
                                                ) : (
                                                    "No"
                                                )
                                            ) : (
                                                "N/A"
                                            )}
                                        </div>
                                    </div>
                                )}
                        </div>
                        {alertMessage && (
                            <Alert
                                message={alertMessage}
                                onClose={() => setAlertMessage(null)}
                            />
                        )}
                        {editVatMode && (
                            <>
                                <div className="mt-4 flex justify-end space-x-2">
                                    <button
                                        onClick={handleCancelVatSettingsEdit}
                                        className="rounded-lg bg-gray-300 px-4 py-2 hover:bg-gray-200 dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={handleVatSettingsSave}
                                        className="rounded-lg bg-brand px-4 py-2 text-white"
                                    >
                                        Save
                                    </button>
                                </div>
                            </>
                        )}
                    </div>

                    <PasswordUpdateModal
                        isOpen={passwordModalOpen}
                        closeModal={() => setPasswordModalOpen(false)}
                    />
                </div>
            </div>
        </div>
    );
}
