import { gql } from "@apollo/client";

export const GET_ACCOUNT_SETTINGS_QUERY = gql`
    query GetAccountSettingsQuery {
        user {
            email
            firstName
            lastName
            dateJoined
            ebayAccount {
                ebayUserId
                storeType
            }
        }
        subscription {
            currentPeriodEnd
            status
            trialEnd
            stripeSubscriptionId
        }
        tierPricing {
            monthlyPrice
            yearlyPrice
            currency
        }
        totalStats {
            totalActiveListings
            totalEndedListings
            totalProductsSold
            totalVatCollected
            totalVatReclaimable
        }
        billingCycle
    }
`;
