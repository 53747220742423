import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "@/contexts/AuthContext";
import CircularLoader from "@/components/loading/LoadingComponent";

interface GuardProps {
    children: React.ReactNode;
}

const InitialisedGuard = ({ children }: GuardProps) => {
    console.log("Executing InitialisedGuard");

    const { ebayAccount, loading } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        console.log("Executing InitialisedGuard useEffect");

        if (!loading && ebayAccount && !ebayAccount.initialised) {
            navigate("/stripe/success");
        }
    }, [ebayAccount, loading, navigate]);

    if (loading) {
        return <CircularLoader />;
    }

    return ebayAccount && ebayAccount.initialised ? <>{children}</> : null;
};

export default InitialisedGuard;
